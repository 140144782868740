import React, { useState } from "react"
import Header from "../components/Header"
import Footer from "../components/Footer"
import CategoryHero from "../components/CategoryHero"
import PremiumSection from "../components/PremiumSection"
import ReviewsSection from "../components/ReviewsSection"
import Featured from "../components/Featured"
import DescriptionSection from "../components/DescriptionSection"
import MassageTypes from "../components/MassageTypes"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import Questions from "../components/Questions"
import CategoriesCard from "../components/CategoriesCard"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

const CategoryPage = ({ data, location }) => {
  const [userLocation, setUserLocation] = useState(
    location && location.state && location.state.location
      ? location.state.location
      : ""
  )

  function getLocationByPostcode() {
    // Return Lat and Long of postcode by Mapbox API Call
    let apiAddress = "/api/mapbox"
    apiAddress += "?postcode=".location // TODO add validation for postcode"
    return fetch(apiAddress).then(res => res.json())
  }
  const getPremium = () => {
    switch (data.descriptionText.shortName) {
      case "Deep Tissue":
        return data.Deep
      case "Swedish":
        return data.Swedish
      case "Sports":
        return data.Sports
      case "Thai":
        return data.Thai
      case "Couples":
        return data.Couples
      case "Hot Stone":
        return data.HotStone
      default:
        break
    }
  }
  return (
    <>
      <SEO
        title={`${data.descriptionText.name} Bristol - Massages Bristol`}
        description={`Find the best ${data.descriptionText.name} Bristol. Compare the best massage deals in bristol and book a relaxing massage today.`}
      />
      <Header />
      <CategoryHero title={data.descriptionText.name} />
      <PremiumSection
        data={getPremium()}
        title={data.descriptionText.name}
        page="cat"
      />
      <CategoriesCard title={data.descriptionText.name} />
      <Featured
        data={data.all}
        title={data.descriptionText.name}
        location={userLocation}
        setLocation={setUserLocation}
        getLocationByPostcode={getLocationByPostcode}
      />
      <ReviewsSection data={data.reviews} />
      <DescriptionSection data={data.descriptionText} />
      <MassageTypes data={data.massageType} />
      <Questions data={data.questions} />
      <Footer />
    </>
  )
}

export default CategoryPage
export const pageQuery = graphql`
  query CategoryBySlug($slug: String!, $shortName: String!) {
    Deep: allContentfulPractitioners(
      limit: 3
      filter: {
        isPremiumDeep: { eq: true }
        massageTypes: { elemMatch: { slug: { eq: $slug } } }
      }
    ) {
      edges {
        node {
          name
          logo {
            fluid {
              ...GatsbyContentfulFluid_noBase64
            }
          }
          mainImage {
            fluid {
              ...GatsbyContentfulFluid_noBase64
            }
          }
          location {
            lat
            lon
          }
          website
          address
          massageTypes {
            shortName
          }
        }
      }
    }
    Swedish: allContentfulPractitioners(
      limit: 4
      filter: {
        isPremiumSwedish: { eq: true }
        massageTypes: { elemMatch: { slug: { eq: $slug } } }
      }
    ) {
      edges {
        node {
          name
          premiumPractitioner
          logo {
            fluid {
              ...GatsbyContentfulFluid_noBase64
            }
          }
          mainImage {
            fluid {
              ...GatsbyContentfulFluid_noBase64
            }
          }
          website
          location {
            lat
            lon
          }
          address
          massageTypes {
            shortName
          }
        }
      }
    }
    Sports: allContentfulPractitioners(
      limit: 4
      filter: {
        isPremiumSports: { eq: true }
        massageTypes: { elemMatch: { slug: { eq: $slug } } }
      }
    ) {
      edges {
        node {
          name
          premiumPractitioner
          logo {
            fluid {
              ...GatsbyContentfulFluid_noBase64
            }
          }
          mainImage {
            fluid {
              ...GatsbyContentfulFluid_noBase64
            }
          }
          location {
            lat
            lon
          }
          website
          address
          massageTypes {
            shortName
          }
        }
      }
    }
    Thai: allContentfulPractitioners(
      limit: 4
      filter: {
        isPremiumThai: { eq: true }
        massageTypes: { elemMatch: { slug: { eq: $slug } } }
      }
    ) {
      edges {
        node {
          name
          premiumPractitioner
          logo {
            fluid {
              ...GatsbyContentfulFluid_noBase64
            }
          }
          mainImage {
            fluid {
              ...GatsbyContentfulFluid_noBase64
            }
          }
          website
          location {
            lat
            lon
          }
          address
          massageTypes {
            shortName
          }
        }
      }
    }
    Couples: allContentfulPractitioners(
      limit: 4
      filter: {
        isPremiumCouples: { eq: true }
        massageTypes: { elemMatch: { slug: { eq: $slug } } }
      }
    ) {
      edges {
        node {
          name
          premiumPractitioner
          logo {
            fluid {
              ...GatsbyContentfulFluid_noBase64
            }
          }
          mainImage {
            fluid {
              ...GatsbyContentfulFluid_noBase64
            }
          }
          location {
            lat
            lon
          }
          website
          address
          massageTypes {
            shortName
          }
        }
      }
    }
    HotStone: allContentfulPractitioners(
      limit: 4
      filter: {
        isPremiumHotStone: { eq: true }
        massageTypes: { elemMatch: { slug: { eq: $slug } } }
      }
    ) {
      edges {
        node {
          name
          premiumPractitioner
          logo {
            fluid {
              ...GatsbyContentfulFluid_noBase64
            }
          }
          mainImage {
            fluid {
              ...GatsbyContentfulFluid_noBase64
            }
          }
          location {
            lat
            lon
          }
          website
          address
          massageTypes {
            shortName
          }
        }
      }
    }
    featured: allContentfulPractitioners(
      limit: 8
      filter: {
        isPremiumHome: { eq: false }
        massageTypes: { elemMatch: { slug: { eq: $slug } } }
      }
    ) {
      edges {
        node {
          name
          premiumPractitioner
          logo {
            fluid {
              ...GatsbyContentfulFluid_noBase64
            }
          }
          mainImage {
            fluid {
              ...GatsbyContentfulFluid_noBase64
            }
          }
          location {
            lat
            lon
          }
          website
          address
          massageTypes {
            shortName
          }
          services {
            price
            time
            link
            massageTypes {
              name
            }
          }
        }
      }
    }
    reviews: allContentfulReview(
      sort: { fields: createdAt, order: DESC }
      limit: 3
      filter: { pages: { elemMatch: { name: { eq: $shortName } } } }
    ) {
      edges {
        node {
          address
          description {
            description
          }
          logo {
            fluid {
              ...GatsbyContentfulFluid_noBase64
            }
          }
          image {
            fluid {
              ...GatsbyContentfulFluid_noBase64
            }
          }
          name
          website
          rating
        }
      }
    }
    massageType: allContentfulMassageType {
      edges {
        node {
          name
          shortName
          description {
            description
          }
          slug
          image {
            fluid {
              ...GatsbyContentfulFluid_noBase64
            }
          }
        }
      }
    }
    all: allContentfulPractitioners(
      filter: { massageTypes: { elemMatch: { slug: { eq: $slug } } } }
    ) {
      nodes {
        name
        logo {
          fluid {
            ...GatsbyContentfulFluid_noBase64
          }
        }
        slug
        premiumPractitioner
        mainImage {
          fluid {
            ...GatsbyContentfulFluid_noBase64
          }
        }
        address
        massageTypes {
          shortName
          name
        }
        location {
          lat
          lon
        }
        postcode
        serviceCards
        website
        price
        durationMin {
          minute
        }
        services {
          price
          time
          link
          massageTypes {
            name
          }
        }
      }
    }
    descriptionText: contentfulMassageType(slug: { eq: $slug }) {
      name
      shortName
      title
      text {
        json
      }
      title
    }
    questions: allContentfulQuestion(
      filter: { pagesRich: { elemMatch: { name: { eq: $shortName } } } }
    ) {
      edges {
        node {
          answer {
            answer
            id
          }
          answer1 {
            id
            json
            answer1
          }
          question
        }
      }
    }
  }
`
